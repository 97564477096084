<template>
  <div class="apps-list"
       v-show="show">
    <div class="option" @click="selectAll($event)">
      <div class="checkbox">
        <input type="checkbox"
               class="common-checkbox"
               :checked="isAllSelected"
        />
      </div>
      <div class="select-all">
        <template v-if="isAllSelected">
          Unselect All
        </template>
        <template v-else>
          Select All
        </template>
      </div>
    </div>
    <div v-for="app in myApps"
         class="option"
         @click="clickRow(app)">
      <div class="checkbox">
        <input type="checkbox"
               class="common-checkbox"
               :checked="isSelected(app)"
        />
      </div>
      <app-title :title="app.info.title"
                 :logo="app.info.logo"
                 :store-link="app.info.store_link"
                 :store="app.store.key"
                 :logo-size="22"
      />
    </div>
  </div>
</template>

<script>
import AppTitle from "@/components/Billets/AppTitle/index.vue";

export default {
  name: 'SummaryMyAppsList',
  components: {AppTitle},
  props: {
    myApps: Array,
    opened: {
      type: Boolean,
      default: false,
    }
  },
  emits: [
    'changed',
  ],
  data() {
    return {
      selected: {},
      show: this.opened
    };
  },
  methods: {
    selectAll() {
      if (this.isAllSelected) {
        this.selected = {};
      } else {
        let selected = {};
        this.myApps.forEach((app) => {
          selected[app.id] = app.id;
        })
        this.selected = selected;
      }
    },
    clickRow(app) {
      let selected = this.selected;
      if (this.isSelected(app)) {
        delete selected[app.id];
      } else {
        selected[app.id] = app.id;
      }
      this.selected = {...selected};
    },
    isSelected(app) {
      return this.selected[app.id] !== undefined;
    }
  },
  computed: {
    isAllSelected() {
      return this.myApps.length === Object.keys(this.selected).length;
    }
  },
  watch: {
    opened() {
      this.show = this.opened;
    },
    selected() {
      this.$emit('changed', Object.keys(this.selected));
    },
  },
}
</script>

<style lang="scss">
.apps-list {
  padding: 0 5px 0;
  max-height: 180px;
  overflow-y: scroll;

  .option {
    align-items: center;
    gap: 7px;
    padding: 4px 0;
  }

  .title-block {
    gap: 7px;

    .title {
      justify-content: space-between;
    }
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #eaeff8;
  }
}
</style>
