<template>
  <div class="reviews-ai-content">
    <div class="download-button-wrap">
      <div class="text">
        Find out what users like and don’t like about any app.
      </div>

      <a :href="downloadUrl"
         class="new-download-button"
         download>
        <svg-icon icon="download"/>
        Download
      </a>
    </div>

    <div class="top-buttons">
      <base-button height="36px"
                   width="185px"
                   border-radius="4px"
                   classes="create-button"
                   :class="{disabled: !isEnabledToCreateSummary}"
                   @click="openCreateModal">
        <svg-icon icon="plus"/>
        <span>Create Summary</span>
      </base-button>

      <div class="progress-wrap">
        <button class="buy-button"
                @click="openModal('showRequestModal')">
          <svg-icon icon="buy-more"/>
          <span>Request More</span>
        </button>

        <div class="progress-block">
          <div class="counts">
            <div class="created-count">
            <span>
              Processed:
              <span v-if="isTableLoading">
                <preloader-table-small loaderSize="16px" />
              </span>
              <span v-else>
                {{ processed }}
              </span>
            </span>
            </div>
            <div class="available-count">
              Available:
              <span v-if="maxAvailable === 0">
                <preloader-table-small loaderSize="16px" />
              </span>
              <span v-else>
                {{ maxAvailable }}
              </span>
            </div>
          </div>
          <progress-bar :width="progressWidth"
                        height="8px"
                        bar-type="gradient"/>
        </div>
      </div>
    </div>

    <div v-if="!isTableLoading && unfilteredTotal === 0" class="empty-page">
      <div class="titles">
        <div class="title">
          No Summaries yet
        </div>
        <div class="sub-title">
          Create a Summary to find out what users like and don’t like about your apps or competitors’ apps
        </div>
      </div>
      <div class="images">
        <div class="list" style="margin-top: 83px;">
          <div class="title">Users Don’t Like 😔</div>
          <table>
            <tr>
              <td class="index"><div>1.</div></td>
              <td>Technical issues such as crashing and erasing data</td>
            </tr>
            <tr>
              <td class="index"><div>2.</div></td>
              <td>Inconsistent billing practices with difficulty in receiving refunds</td>
            </tr>
            <tr>
              <td class="index"><div>3.</div></td>
              <td>Migration problems when transferring the app to a new device</td>
            </tr>
          </table>
        </div>
        <div class="image">
          <svg-icon icon="empty-reviews-summaries"/>
        </div>
        <div class="list" style="margin-top: 95px;">
          <div class="title">Users Like 😄</div>
          <table>
            <tr>
              <td class="index"><div>1.</div></td>
              <td>Easy to use interface</td>
            </tr>
            <tr>
              <td class="index"><div>2.</div></td>
              <td>Ability to add photos and signatures to invoices</td>
            </tr>
            <tr>
              <td class="index"><div>3.</div></td>
              <td>Good customer service and fast problem resolution</td>
            </tr>
            <tr>
              <td class="index"><div>4.</div></td>
              <td>Lifetime membership option</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <SimpleInfoTable v-else
                     :data-provider="dataProvider"
                     :columns="{
                       'title': {
                         header: 'Summary Name',
                         orientation: 'left',
                         style: 'width: 100%',
                       },
                       'status': {header: ''},
                       'rating': {header: 'Rating', orientation: 'left'},
                       'dates': {
                         header: 'Date Range',
                         sortBy: (item) => new Date(item.date_from),
                         orientation: 'left',
                         sortExportParam: 'date_from'
                       },
                       'country': {
                         header: 'Country/Locale',
                         orientation: 'left',
                       },
                       'created': {
                         header: 'Created',
                         sortBy: (item) => new Date(item.created_at),
                         sortExportParam: 'date',
                         orientation: 'left'
                       },
                       'delete': {header: ''}
                     }"
                     disabled-prop="in_process"
                     :default-sort-index="'created'"
                     :default-sort-direction="'desc'"
                     :full-width-section="true"
                     :multiselect="true"
                     :trClickCallback="openRow"
                     :tr-class-callback="trClassCallback"
                     @item-checked="itemChecked"
                     @all-items-checked="allItemsChecked"
    >
      <template v-slot:headers="slotProps">
        <div :class="'header-title-' + slotProps.index">
          <div>
            {{ slotProps.header }}
          </div>

          <table-actions-tooltip v-if="slotProps.index === 'title' && this.checkedItems.length > 0"
                                 class="group-delete"
                                 @click="deleteChecked($event)">
            <template v-slot:tooltip-icon>
              <svg-icon icon="close" />
            </template>
            <template v-slot:tooltip-text>
              Delete selected
            </template>
          </table-actions-tooltip>
        </div>
      </template>

      <template v-slot:searchFilterBlock>
        <div>
          <div class="collapse-button mr-15"
               :class="{disabled: !haveSummariesToUncollapse}"
               @click="collapseClickHandler">
            <template v-if="isAllUncollapsed && haveSummariesToUncollapse">
              <div>Collapse all</div>
              <svg-icon icon="collapse"/>
            </template>
            <template v-else>
              <div>Uncollapse all</div>
              <svg-icon icon="uncollapse"/>
            </template>
          </div>

          <summary-app-filter default-value="all"
                              :filter="selectedFilter"
                              :apps="filterApps"
                              @apply="applyFilter"/>
        </div>
      </template>

      <template v-slot:tableOptions>
        <base-button height="36px"
                     width="100px"
                     border-radius="4px"
                     mode="outline"
                     @click="fetchList">
          <span>Refresh</span>
        </base-button>
      </template>

      <template v-slot:full-width-sections="slotProps">
        <div v-if="slotProps.item.result && slotProps.item.opened"
             class="summary-result">
          <div class="result-header">
            <div class="left-part">
              <div class="result-param">
                <span class="title">Average Reviews Rating: </span>
                <span>{{ slotProps.item.result.rating }} <span class="css-icons star-icon"></span></span>
              </div>
              <div class="result-param rating-param">
                <ratings-grade :data="slotProps.item.result.rating_counts" />
              </div>
            </div>
            <div class="result-param">
              <span class="title">Created: </span>
              <span>{{ formatDate(slotProps.item.result.created_at) }}</span>
            </div>
          </div>
          <div class="result-content">
            <div class="overview">
              <div class="content-title">
                Overview
              </div>
              <div>
                {{ slotProps.item.result.overview }}
              </div>
            </div>
            <div class="strengths">
              <div class="content-title">
                Users Like 😊
              </div>
              <div>
                <table>
                  <tr v-for="(strength, index) in slotProps.item.result.strengths">
                    <td class="index"><div>{{ index + 1 }}.</div></td>
                    <td>{{ capitalizeFirstLetter(strength) }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="weaknesses">
              <div>
                <div class="content-title">
                  Users Don’t Like 😔
                </div>
                <div>
                  <table>
                    <tr v-for="(weakness, index) in slotProps.item.result.weaknesses">
                      <td class="index"><div>{{ index + 1 }}.</div></td>
                      <td>{{ capitalizeFirstLetter(weakness) }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:items-title="slotProps">
        <div class="title-wrap">
          <app-title :title="slotProps.item.app.title"
                     :logo="slotProps.item.app.logo"
                     :store-link="slotProps.item.app.store_link"
                     :store="slotProps.item.app.store"
          />

          <svg-icon v-if="slotProps.item.result"
                    icon="angle-down-solid"
                    class="cursor-pointer open-full-section"
                    :class="{rotate180: slotProps.item.opened}" />
        </div>
      </template>
      <template v-slot:items-status="slotProps">
        <div class="status" :class="slotProps.item.status">
          <table-actions-tooltip>
            <template v-slot:tooltip-icon>
              <span v-if="slotProps.item.status === 'done'">🟢</span>
              <span v-else-if="slotProps.item.status === 'process'">
                <preloader-table-small loader-size="20px"/>
              </span>
              <span v-else-if="slotProps.item.status === 'error'">🔴</span>
              <span v-else-if="slotProps.item.status === 'no_reviews'">⚪️</span>
              <span v-else-if="slotProps.item.status === 'not_enough'">⚪️</span>
            </template>
            <template v-slot:tooltip-text>
              <span v-if="slotProps.item.status === 'done'">
                Your report is ready. Click on the report to view details.
              </span>
              <span v-else-if="slotProps.item.status === 'process'">
                Currently processing and analyzing reviews. You probably won't have time to brew a coffee while waiting for this report, so don't wander off too far! And don't forget to click <span class="bold">Refresh</span> button.
              </span>
              <span v-else-if="slotProps.item.status === 'error'">
                Oops, an error occurred. But no worries, error reports don't count against your limits – we don't charge for oopsies! You can try to <span class="bold">regenerate</span> the report.
              </span>
              <span v-else-if="slotProps.item.status === 'no_reviews'">
                No reviews found for the selected period. Try extending the analysis period if the application has few reviews.
              </span>
              <span v-else-if="slotProps.item.status === 'not_enough'">
                Unable to generate analysis due to a low number of reviews. Try extending the analysis period if the application has few reviews.
              </span>
            </template>
          </table-actions-tooltip>
        </div>
      </template>
      <template v-slot:items-rating="slotProps">
        <div class="rating">
          <div class="css-icons star-icon"></div>
          <div>
            <span>{{ slotProps.item.rating_from }}-{{ slotProps.item.rating_to }}</span>
            <span v-if="slotProps.item.result"
                 class="reviews-count"
                 @click="openReviewsModal($event, slotProps.item)">
              ({{ slotProps.item.result.reviews_count }})
            </span>
          </div>
        </div>
      </template>
      <template v-slot:items-dates="slotProps">
        <div class="dates">
          {{ formatDates(slotProps.item.date_from, slotProps.item.date_to) }}
        </div>
      </template>
      <template v-slot:items-created="slotProps">
        <div class="dates">
          {{ formatDate(slotProps.item.created_at) }}
        </div>
      </template>
      <template v-slot:items-country="slotProps">
        <div class="country-title">
          <template v-if="slotProps.item.app.store === 'APP_STORE'">
            <dynamic-image classes="country-flag"
                           :width="14"
                           :height="10"
                           :country-code="slotProps.item.country_code"/>
            <div>
              {{ slotProps.item.country }}
            </div>
          </template>
          <template v-else>
            <template v-if="localeCountryCode(slotProps.item.locale)?.countryCode">
              <dynamic-image classes="country-flag"
                             :width="14"
                             :height="10"
                             :country-code="localeCountryCode(slotProps.item.locale).countryCode"/>
            </template>
            <span>{{ localeCountryCode(slotProps.item.locale)?.localeFullName }}</span>
          </template>
        </div>
      </template>
      <template v-slot:items-delete="slotProps">
        <div class="actions-column">
          <table-actions-tooltip class="refresh-button"
                                 v-if="canRegenerateSummary(slotProps.item)"
                                 @click="regenerate(slotProps.item, $event)">
            <template v-slot:tooltip-icon>
              <svg-icon icon="refresh" />
            </template>
            <template v-slot:tooltip-text>
              Regenerate
            </template>
          </table-actions-tooltip>
        </div>
      </template>
    </SimpleInfoTable>
  </div>

  <create-summary-modal ref="create_summary_modal"
                        :preset-data="newSummaryPresets"
                        @created="fetchList"
                        @error="creationError"
                        @limit_reached="reachLimit"
  />

  <reviews-modal :show-modal="showReviewsModal"
                 :app="reviewsSummary.app"
                 :country="{
                   name: reviewsSummary.country,
                   code: reviewsSummary.country_code,
                 }"
                 :date-filter="{
                   from: reviewsSummary.date_from,
                   to: reviewsSummary.date_to,
                 }"
                 :rating-filter="{
                   from: reviewsSummary.rating_from,
                   to: reviewsSummary.rating_to,
                 }"
                 :locale="reviewsSummary.locale"
                 @close="showReviewsModal = false"
  />

  <request-more-summaries-modal :show-modal="showRequestModal"
                                @close="closeModal('showRequestModal')"
                                @submit="sendRequestForm" />

  <alert :text="alertText" @hide="alertText = ''" />
</template>

<script>
import SimpleInfoTable from "@/components/SimpleInfoTable/SimpleInfoTable.vue";
import {formatDate, cropImage} from "@/utils/utils";
import ProgressBar from "@/components/UI/ProgressBar/index.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import Preloader from "@/components/UI/Preloader/index.vue";
import CreateSummaryModal from "@/views/CompetitorsFinderPage/Competitors/ReviewsAI/components/CreateSummaryModal/index.vue";
import {httpRequest} from "@/api";
import ProgressIndicator from "@/components/UI/ProgressIndicator/index.vue";
import {SimpleDataProvider} from "@/api/modules/tables/simple-data-provider";
import {PaginationData, SortingData} from "@/api/modules/tables/search-filter-data";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import CustomDropdown from "@/components/UI/CustomDropdown/index.vue";
import SummaryAppFilter
  from "@/views/CompetitorsFinderPage/Competitors/ReviewsAI/components/SummaryAppFilter/index.vue";
import AppTitle from "@/components/Billets/AppTitle/index.vue";
import {mapGetters} from "vuex";
import Alert from "@/components/UI/Alert/index.vue";
import {getLocaleCountryCode} from "@/configs/locales-flags";
import TableActionsTooltip from "@/components/DataTables/Tools/TableActionsTooltip/index.vue";
import RequestMoreSummariesModal
  from "@/views/CompetitorsFinderPage/Competitors/ReviewsAI/components/RequestMoreSummariesModal/index.vue";
import ReviewsModal from "@/views/CompetitorsFinderPage/Competitors/ReviewsAI/components/ReviewsModal/index.vue";
import RatingsGrade from "@/views/CompetitorsFinderPage/Competitors/ReviewsAI/components/RatingsGrade/index.vue";

export default {
  name: 'competitors_tab_reviews',
  components: {
    RatingsGrade,
    ReviewsModal,
    RequestMoreSummariesModal,
    TableActionsTooltip,
    Alert,
    AppTitle,
    SummaryAppFilter,
    CustomDropdown,
    SvgIcon,
    ProgressIndicator,
    CreateSummaryModal,
    Preloader,
    PreloaderTableSmall,
    ProgressBar,
    SimpleInfoTable
  },
  data() {
    return {
      env: process.env.VUE_APP_URL,
      items: [],
      dataProvider: new SimpleDataProvider(
        this.items,
        [],
        [function (item) { return item.app.title; }],
        new PaginationData(),
        new SortingData(
          'created',
          'desc',
          'date'
        ),
        true
      ),
      processed: 0,
      unfilteredTotal: 0,
      selectedFilter: 'all',
      filterApps: [],
      checkedItems: [],
      alertText: '',
      maxAvailable: 0,
      showRequestModal: false,
      showReviewsModal: false,
      reviewsSummary: {},
      newSummaryPresets: {},
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    cropImage,
    formatDate,
    canRegenerateSummary(item) {
      if (item.status === 'done') {
        return true;
      } else if (item.status === 'error' && (item.regeneration_attempts <= item.max_regeneration_attempts)) {
        return true;
      }
      return false;
    },
    openReviewsModal(event, item) {
      event.preventDefault();
      event.stopPropagation();
      if (this.isFreeUser) {
        this.openPlansModal();
      } else {
        this.reviewsSummary = item;
        this.showReviewsModal = true;
      }
    },
    openPlansModal() {
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
    async sendRequestForm(requestFormData) {
      const formData = new FormData();
      formData.append("author_name", requestFormData.name);
      formData.append("author_email", requestFormData.email);
      formData.append("content", requestFormData.content);
      formData.append("from_url", window.location.origin + this.$route?.path);
      formData.append("type", "request-summaries");
      const result = await httpRequest(
        'POST',
        process.env.VUE_APP_API_URL + this.$ApiUrls.user.CONTACTS,
        formData
      );

      if (!result?.errors && !result?.message) {
        this.alertText = "You made a request for additional Summaries. We will contact you ";
      }

      this.showRequestModal = false;
    },
    localeCountryCode(code) {
      return getLocaleCountryCode(code);
    },
    formatDates(dateFrom, dateTo) {
      return formatDate(dateFrom) + ' - ' + formatDate(dateTo);
    },
    applyFilter(selectedFilter, filterApps) {
      this.selectedFilter = selectedFilter;
      this.filterApps = filterApps;
    },
    allItemsChecked(isChecked) {
      let data = this.dataProvider.getItems();
      this.checkItems(data, isChecked)
    },
    deleteChecked(event) {
      let ids = this.checkedItems.map((item) => item.id);
      this.checkedItems = [];
      this.deleteSummary(ids, event);
    },
    checkItems(items, isChecked) {
      let allItems = this.dataProvider.getAllItems();
      allItems.forEach((item) => {
        if (items.includes(item)) {
          item.checked = isChecked;
        }
      });
      this.dataProvider.setItems(allItems);

      this.checkedItems = allItems.filter((item) => {
        return item.checked === true;
      });
    },
    itemChecked(item, isChecked) {
      this.checkItems([item], isChecked);
    },
    collapseClickHandler() {
      let opened = !this.isAllUncollapsed;
      let items = this.dataProvider.getItems();
      items.forEach((item) => {
        item.opened = opened;
      });
      this.dataProvider.setItems(items);
    },
    openCreateModal() {
      if (this.isEnabledToCreateSummary) {
        this.$refs.create_summary_modal.openModal();
      }
    },
    async fetchList() {
      this.newSummaryPresets = {};
      this.checkedItems = [];
      this.dataProvider.setLoading(true);
      let result = await httpRequest('GET', this.requestUrl);
      if (result.list !== undefined) {
        this.dataProvider.setItems(result.list);
        this.processed = result.processed;
        this.unfilteredTotal = result.unfiltered_total;
        this.maxAvailable = result.available;
      }
    },
    async fetchCounts() {
      let result = await httpRequest('GET', this.requestUrl);
      this.processed = result.processed;
      this.unfilteredTotal = result.unfiltered_total;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async deleteSummary(ids, event) {
      event.preventDefault();
      event.stopPropagation();
      this.deleteItems(ids);
      let url = process.env.VUE_APP_API_URL + this.$ApiUrls.reviewSummaries.SUMMARY_DELETE + '?';
      ids.forEach((id) => {
        url += 'ids[]=' + id + '&'
      });
      await httpRequest('DELETE', url);
      await this.fetchCounts();
    },
    async regenerate(item, event) {
      event.preventDefault();
      event.stopPropagation();

      if (item.status === 'done') {
        if (this.isEnabledToCreateSummary) {
          this.newSummaryPresets = {
            app: item.app,
            country: {
              code: item.country_code,
              name: item.country,
              id: item.country_id,
            },
            locale: item.locale,
            dateFrom: new Date(item.date_from),
            dateTo: new Date(item.date_to),
            ratingFrom: item.rating_from,
            ratingTo: item.rating_to,
          };
          this.$refs.create_summary_modal.openModal();
        } else {
          this.openModal('showRequestModal');
        }
      } else {
        this.alertText = 'The summary will be regenerated.';
        let items = this.dataProvider.getAllItems();
        items.forEach((summary) => {
          if (summary.id === item.id) {
            summary.status = 'process';
          }
        });
        this.dataProvider.setItems(items);

        await httpRequest(
          'GET',
          process.env.VUE_APP_API_URL + this.$ApiUrls.reviewSummaries.SUMMARY_REFRESH + '?id=' + item.id
        );
      }
    },
    creationError() {
      this.alertText = 'Something went wrong during creation of a summary.';
    },
    reachLimit() {
      this.alertText = 'The limit has been reached. You can buy more attempts.';
    },
    deleteItems(ids) {
      let items = this.dataProvider.getAllItems();
      items = items.filter((item) => !ids.includes(item.id));
      this.dataProvider.setItems(items);
    },
    openRow(item, event) {
      if (event.target.type !== 'checkbox') {
        item.opened = !item.opened;
      }
    },
    trClassCallback(item) {
      return {'cursor-pointer': item.result};
    },
  },
  watch: {
    requestUrl() {
      this.fetchList();
    },
  },
  computed: {
    ...mapGetters([
      'userSubscription',
    ]),
    isFreeUser() {
      return this.userSubscription?.plan_name === 'Free';
    },
    requestUrl() {
      let url = process.env.VUE_APP_API_URL + this.$ApiUrls.reviewSummaries.REVIEWS_SUMMARIES
        + '?filter[with_applications][type]=' + this.selectedFilter;
      this.filterApps.forEach((id) => {
        url += '&filter[with_applications][ids][]=' + id;
      });

      return url;
    },
    downloadUrl() {
      let url = process.env.VUE_APP_URL + this.$ApiUrls.exportsUrls.REVIEWS_SUMMARIES
        + '?filter[with_applications][type]=' + this.selectedFilter;
      this.filterApps.forEach((id) => {
        url += '&filter[with_applications][ids][]=' + id;
      });
      url += this.dataProvider.getSorting().generateFilterString();
      url += '&search=' + this.dataProvider.getSearchFilterData().searchInput

      return url;
    },
    isAllUncollapsed() {
      if (this.isTableLoading) {
        return false;
      } else {
        return this.dataProvider.getItems().every((item) => item.opened === true || item.result === null);
      }
    },
    haveSummariesToUncollapse() {
      if (this.isTableLoading) {
        return false;
      } else {
        return this.dataProvider.getItems().find((item) => item.result !== null);
      }
    },
    progressWidth() {
      if (this.processed === 0 || this.maxAvailable === 0) {
        return '100%';
      } else if (this.processed >= this.maxAvailable) {
        return '0%';
      } else {
        let width = this.processed / (this.maxAvailable / 100);
        return (100 - width) + '%';
      }
    },
    isTableLoading() {
      return this.dataProvider.isLoading();
    },
    isEnabledToCreateSummary() {
      return !this.isTableLoading && this.processed < this.maxAvailable;
    },
  }
}
</script>

<style src="./styles.scss" lang="scss"></style>
