<template>
  <custom-dropdown :min-width="'100px'"
                   classes="apps-filter"
                   :close-dropdown="closeDropdown">
    <template v-slot:header>
      <span v-if="filter === 'all'">All Apps</span>
      <div v-else class="selected-filter">
        <div class="title">
          <div v-if="filter === 'my'">My Apps:</div>
          <div v-else>Competitors for:</div>
        </div>
        <div class="logos">
          <div v-if="apps.length === myApps.length" class="all-selected">
            All
          </div>
          <template v-else>
            <div v-for="app in appsForTitle">
              <img :width="18"
                   :height="18"
                   :src="cropImage(app.logo, '18x18', app.store)"
              />
            </div>
            <div v-if="appsForTitle.length < apps.length">
              <div>...</div>
            </div>
          </template>
        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="select-options">
        <div class="option" @click="selectedFilter = 'all'">
          <div class="circle" :class="{active: selectedFilter === 'all'}"></div>
          <div class="option-title">All Apps</div>
        </div>
        <div>
          <div class="option" @click="selectedFilter = 'my'">
            <div class="circle" :class="{active: selectedFilter === 'my'}"></div>
            <div class="option-title">My Apps</div>
          </div>
          <div v-if="error && selectedFilter === 'my'" class="error-message">
            Please select at least one app.
          </div>
          <summary-my-apps-list :opened="selectedFilter === 'my'"
                                @changed="(e) => this.selectedApps = e"
                                :my-apps="myApps" />
        </div>
        <div>
          <div class="option" @click="selectedFilter = 'competitors'">
            <div class="circle" :class="{active: selectedFilter === 'competitors'}"></div>
            <div class="option-title">Competitors for selected My Apps</div>
          </div>
          <div v-if="error && selectedFilter === 'competitors'" class="error-message">
            Please select at least one app.
          </div>
          <summary-my-apps-list :opened="selectedFilter === 'competitors'"
                                @changed="(e) => this.selectedAppsForCompetitors = e"
                                :my-apps="myApps" />
        </div>
      </div>
      <div class="filters-nav">
        <base-button height="36px"
                     width="75px"
                     border-radius="4px"
                     mode="outline"
                     @click="clear">
          <span>Clear</span>
        </base-button>
        <base-button height="36px"
                     width="75px"
                     border-radius="4px"
                     @click="apply">
          <span>Apply</span>
        </base-button>
      </div>
    </template>
  </custom-dropdown>
</template>

<script>
import CustomDropdown from "@/components/UI/CustomDropdown/index.vue";
import myApps from "@/api/modules/my-apps";
import SummaryMyAppsList
  from "@/views/CompetitorsFinderPage/Competitors/ReviewsAI/components/SummaryMyAppsList/index.vue";
import {cropImage} from "@/utils/utils";

export default {
  name: 'SummaryAppFilter',
  components: {
    SummaryMyAppsList,
    CustomDropdown,
  },
  emits: [
    'apply',
  ],
  props: {
    defaultValue: String,
    filter: String,
    apps: Array,
  },
  data() {
    return {
      closeDropdown: 0,
      selectedFilter: this.defaultValue,
      myApps: [],
      selectedApps: [],
      selectedAppsForCompetitors: [],
      error: false,
    };
  },
  mounted() {
    this.fetchMyApps();
  },
  methods: {
    cropImage,
    async fetchMyApps() {
      this.myApps = await myApps.getAllMyApps();
    },
    apply() {
      let apps = [];
      if (this.selectedFilter !== 'all') {
        switch (this.selectedFilter) {
          case 'my':
            apps = this.selectedApps;
            break;
          case 'competitors':
            apps = this.selectedAppsForCompetitors;
        }
        if (apps.length === 0) {
          this.error = true;
          return;
        }
      }

      this.$emit('apply', this.selectedFilter, apps);
      this.hide();
    },
    clear() {
      this.selectedFilter = this.defaultValue;
      this.selectedApps = [];
      this.selectedAppsForCompetitors = [];
      this.$emit('apply', this.defaultValue, []);
      this.hide();
    },
    hide() {
      this.closeDropdown++;
    },
    checkErrors() {
      if (this.selectedFilter === 'my' && this.selectedApps.length > 0) {
        this.error = false;
      }
      if (this.selectedFilter === 'competitors' && this.selectedAppsForCompetitors.length > 0) {
        this.error = false;
      }
    }
  },
  computed: {
    appsForTitle() {
      let ids = this.apps.slice(0, 5).map((id) => +id);
      let logos = [];
      this.myApps.forEach((item) => {
        if (ids.includes(item.id)) {
          logos.push({
            logo: item.info.logo,
            store: item.store.key,
          });
        }
      });

      return logos;
    },
  },
  watch: {
    selectedApps() {
      this.checkErrors();
    },
    selectedAppsForCompetitors() {
      this.checkErrors();
    },
    selectedFilter() {
      this.checkErrors();
    }
  }
}
</script>

<style src="./styles.scss" lang="scss"></style>
