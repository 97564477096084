<template>
  <custom-modal :opened="showModal"
                :max-width="520"
                class-name="request-feature-modal request-summaries-modal"
                @closeModal="$emit('close')">
    <div class="modal-message textarea-block">
      <div class="form-row">
        <div class="form-col w100p">
          <div class="modal-title">
            AI Summaries Request
          </div>
        </div>
      </div>
      <div class="description">
        <div>You can buy every 10 AI Summaries for 19.99 USD.</div>
        <div>Leave us a request, and we will contact you for AI Summaries purchasing details.</div>
      </div>
      <div class="form-row">
        <div class="form-col w100p">
          <div class="form-control">
            <label>Comment (optionally)</label>
            <label>
              <input name="content"
                     v-model="requestFormData.comment"
                     placeholder="Enter a comment if needed"
              />
            </label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-col w100p">
          <div class="form-control">
            <label>Enter the number of Summaries you want:</label>
            <label>
              <input name="count"
                     type="number"
                     step="10"
                     min="10"
                     v-model="requestFormData.count"
              />
            </label>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-col w100p">
          <div class="form-control">
            <div class="prices">
              <div class="price" v-for="price in prices">
                <div class="block" @click="clickPrice(price)">
                  <div class="overall">
                    ${{ price.price }} / <span class="count">{{ price.count }}</span>
                  </div>
                  <div class="per-report">
                    ${{ price.perReport }} / report
                  </div>
                </div>
                <div class="discount" v-if="price.discount">
                  -{{ price.discount }}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-col mb-10 w100p buy-pro">
          Or <span @click="openPlansModal" class="pro-link">buy Pro Plan</span> for $199 and get all features +20 reports every month
        </div>
      </div>

      <div class="form-row">
        <div class="form-col w100p btn-block">
          <base-button height="40px"
                       width="120px"
                       font-size="15px"
                       class="btn btn-default"
                       :class="{'disabled gray': requestFormData.count < 1}"
                       :disabled="requestFormData.count < 1"
                       @click="sendContactForm">
            Request Summaries
          </base-button>
          <base-button height="40px"
                       width="120px"
                       font-size="15px"
                       class="btn btn-default"
                       @click="showModal = false"
                       mode="outline">
            Cancel
          </base-button>
        </div>
      </div>
    </div>
  </custom-modal>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'RequestMoreSummariesModal',
  emits: [
    'submit',
    'close',
  ],
  props: {
    showModal: Boolean
  },
  data() {
    return {
      requestFormDataDefault: {
        count: 10,
      },
      requestFormData: {
        count: 10,
      },
      prices: [
        {
          count: 10,
          price: 19.90,
          perReport: 1.99,
          discount: 0,
        },
        {
          count: 20,
          price: 35.82,
          perReport: 1.79,
          discount: 10,
        },
        {
          count: 50,
          price: 69.65,
          perReport: 1.39,
          discount: 30,
        },
        {
          count: 100,
          price: 119.40,
          perReport: 1.19,
          discount: 40,
        },
      ],
    };
  },
  methods: {
    openPlansModal() {
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
    clickPrice(price) {
      this.requestFormData.count = price.count;
    },
    sendContactForm() {
      this.requestFormData.name = this.userData.name;
      this.requestFormData.email = this.userData.email;

      let content = "Request for additional summaries: " + this.requestFormData.count + '.';
      if (this.requestFormData.comment) {
        content += " Comment: " + this.requestFormData.comment;
      }
      this.requestFormData.content = content;

      this.$emit('submit', this.requestFormData);
    },
  },
  computed: {
    ...mapGetters([
      'userData',
    ]),
  },
  watch: {
    showModal() {
      this.requestFormData = {...this.requestFormDataDefault};
    }
  }
}
</script>

<style src="./styles.scss" scoped lang="scss"></style>
