<template>
  <div class="calendar-block"
       v-click-outside="outside"
       :class="{active: showDatepicker}">
    <div class="calendar-input"
         @click="showDatepicker = !showDatepicker">
      <div class="input-value">
        {{ formatDate(dateFilter.start) }} - {{ formatDate(dateFilter.end) }}
      </div>
      <div class="input-caret">
        <svg-icon icon="angle-down-solid"/>
      </div>
    </div>

    <div class="calendar-block-wrap" :class="{'right':toRight}" style="width: initial; flex-wrap: initial">
      <v-date-picker v-model="tempDate"
                     is-range
                     :min-date="minDate"
                     :max-date="maxDate"
                     :locale="calendarLocale"
                     :key="datePickerKey"
                     :columns="columns"/>

      <div class="date-picker-range">
        <div class="range-items-list">
          <div class="range-item"
               v-for="range in ranges"
               @click="getLastDaysRange(range)"
               :class="{active: currentDataRange === range}">
            Last {{range}} Days
          </div>
        </div>
        <div class="bottom-part" style="flex-direction: column">
          <base-button height="30px"
                       width="100%"
                       font-size="14px"
                       border-radius="4px"
                       style="width: 100%"
                       @click="applyDatePicker">Apply
          </base-button>
          <base-button mode="outline"
                       height="30px"
                       width="100%"
                       font-size="14px"
                       border-radius="4px"
                       style="width: 100%; margin-top: 10px"
                       @click="closeDatePicker">Cancel
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Calendar, DatePicker} from 'v-calendar';
import 'v-calendar/dist/style.css';
import {formatDate} from "@/utils/utils";

export default {
  name: "DatepickerWithRangeOld",
  props: {
    toRight: {
      type: Boolean,
      default: false
    },
    minDate: {
      type: Date,
    },
    maxDate: {
      type: Date,
    },
    dateFilter: {
      type: Object,
    },
    columns: {
      type: Number,
    },
    lastDaysCount: {
      type: Number,
      default: 14
    },
    ranges: {
      type: Array,
      default: [
        90,
        180
      ]
    }
  },
  components: {
    'v-calendar': Calendar,
    'v-date-picker': DatePicker,
  },
  emits: ['closeModal', 'date-filter-changed', 'date-filter-closed'],
  data() {
    return {
      showDatepicker: false,
      tempDate: {
        start: new Date(),
        end: new Date(),
      },
      calendarLocale: {
        masks: {weekdays: 'WW'},
      },
      datePickerKey: 0,
    }
  },
  mounted() {
    this.getLastDaysRange(this.lastDaysCount);
    this.tempDate = {...this.dateFilter};
  },
  methods: {
    applyDatePicker() {
      this.showDatepicker = false;
      this.$emit('date-filter-changed', this.tempDate);
    },
    closeDatePicker() {
      this.showDatepicker = false;
      this.tempDate = {...this.dateFilter};
    },
    formatDate(val) {
      return formatDate(val, 'month-day-year');
    },
    outside() {
      // this.showDatepicker = false;
      this.closeDatePicker();
    },
    getLastDaysRange(days) {
      const nowStart = new Date(this.maxDate);
      const nowEnd = new Date(this.maxDate);

      const tempDate = {
        start: nowStart.setDate(nowStart.getDate() - days),
        end: nowEnd.setDate(nowEnd.getDate()),
      }

      this.tempDate = {...tempDate};
      this.datePickerKey++;
    },
  },
  computed: {
    currentDataRange() {
      let diffTime = Math.abs(this.tempDate.start - this.tempDate.end);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
  },
}
</script>

<style scoped lang="scss" src="./styles.scss"></style>
<style scoped>
.date-picker-range {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.calendar-block-wrap.right {
  right: 0;
}
.calendar-block .bottom-part {
  padding: 16px 0;
}
</style>