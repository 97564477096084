<template>
  <div class="multi-actions-item">
    <div class="icon-wrap">
      <slot name="tooltip-icon"></slot>
    </div>

    <div class="tooltip">
      <div class="text">
        <slot name="tooltip-text"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "TableActionsTooltip"
})
</script>

<style scoped lang="scss">
.multi-actions-item {
  display: flex;
  align-items: center;
  position: relative;
}

.tooltip {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease 0s;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(160, 190, 221, 0.30);
  padding: 4px 16px;
  top: 100%;
  left: 50%;
  text-align: left;
  transform: translateX(-50%);
  z-index: 100;

  .text {
    color: var(--neutral-800);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
  }
}

.icon-wrap:hover + .tooltip {
  top: calc(100% + 8px);
  visibility: visible;
  opacity: 1;
}
</style>