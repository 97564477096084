<template>
  <custom-modal :opened="showModal"
                :max-width="600"
                class-name="reviews-modal"
                @closeModal="$emit('close')">
    <div class="modal-message textarea-block">
      <div class="modal-title">
        <div>Reviews for</div>
        <app-title :title="app.title"
                   :store="app.store"
                   mode="with-link"
                   :logo-size="25"
                   :store-icon-size="15"
                   :store-link="app.store_link"
                   :logo="app.logo" />
      </div>

      <div class="props-wrap">
        <div class="props">
          <div class="prop" v-if="locale">
            <div class="label">Locale:</div>
            <div class="country-prop">
              <template v-if="localeCountryCode(locale)?.countryCode">
                <dynamic-image classes="country-flag"
                               :width="14"
                               :height="10"
                               :country-code="localeCountryCode(locale).countryCode"/>
              </template>
              <span>{{ localeCountryCode(locale)?.localeFullName }}</span>
            </div>
          </div>
          <div class="prop" v-else>
            <div class="label">Country:</div>
            <div class="country-prop">
              <dynamic-image classes="country-flag"
                             :width="14"
                             :height="10"
                             :country-code="country.code"/>
              <div>
                United States
              </div>
            </div>
          </div>
          <div class="prop">
            <div>
              <span class="css-icons star-icon"></span> {{ ratingFilter.from }}-{{ ratingFilter.to }}
            </div>
          </div>
        </div>

        <div class="props">
          <div class="prop">
            <div class="label">Dates:</div>
            <div>
              {{ dateFilter.from }} - {{ dateFilter.to }}
            </div>
          </div>
        </div>
      </div>

      <div class="show-skipped-checkbox">
        <div class="checkboxes-block type-2">
          <input type="checkbox"
                 name="compact-mode"
                 id="compact-mode"
                 v-model="showSkipped"
                 value="1">
          <label for="compact-mode">Skipped by Length</label>
        </div>
      </div>

      <SimpleInfoTable v-if="!isReviewsLoading"
                       :columns="{
                          date: {header: 'Date', orientation: 'left', sortBy: (item) => new Date(item.date)},
                          rating: {header: 'Rating', sortBy: 'rating'},
                       }"
                       default-sort-index="date"
                       default-sort-direction="desc"
                       :full-width-section="true"
                       :items="preparedReviews">
        <template v-slot:items-date="slotProps">
          <div class="font-14">
            <div class="review-date-block">
              <div>{{ slotProps.item.date }}</div>
              <table-actions-tooltip v-if="slotProps.item.skipped">
                <template v-slot:tooltip-icon>
                  <div class="skipped-label">Skipped by length</div>
                </template>
                <template v-slot:tooltip-text>
                  We analyze only reviews that are 18 characters or longer to ensure meaningful feedback is considered.
                </template>
              </table-actions-tooltip>
            </div>
          </div>
        </template>
        <template v-slot:items-rating="slotProps">
          <div class="font-14">
            <span>{{ slotProps.item.rating }} <span class="css-icons star-icon"></span></span>
          </div>
        </template>

        <template v-slot:full-width-sections="slotProps">
          <div class="review">
            {{ slotProps.item.content }}
          </div>
        </template>
      </SimpleInfoTable>
      <div class="simple-info-table-loading" v-else>
        <progress-indicator :loaded="dataIsLoaded">
          <slot>
            Loading...
          </slot>
        </progress-indicator>
      </div>
    </div>
  </custom-modal>
</template>

<script>
import AppTitle from "@/components/Billets/AppTitle/index.vue";
import {httpRequest} from "@/api";
import SimpleInfoTable from "@/components/SimpleInfoTable/SimpleInfoTable.vue";
import ProgressIndicator from "@/components/UI/ProgressIndicator/index.vue";
import TableActionsTooltip from "@/components/DataTables/Tools/TableActionsTooltip/index.vue";
import {getLocaleCountryCode} from "@/configs/locales-flags";

export default {
  name: 'ReviewsModal',
  components: {TableActionsTooltip, ProgressIndicator, SimpleInfoTable, AppTitle},
  props: {
    showModal: Boolean,
    app: Object,
    dateFilter: Object,
    ratingFilter: Object,
    country: Object,
    locale: String,
  },
  data() {
    return {
      reviews: [],
      isReviewsLoading: true,
      showSkipped: false,
    };
  },
  methods: {
    localeCountryCode(code) {
      return getLocaleCountryCode(code);
    },
    async fetchReviews() {
      this.isReviewsLoading = true;
      let result = await httpRequest('GET', this.requestUrl);
      this.reviews = result.result;
      this.isReviewsLoading = false;
    }
  },
  computed: {
    requestUrl() {
      return process.env.VUE_APP_API_URL + this.$ApiUrls.reviewSummaries.REVIEWS_LIST
        + '?application_id=' + this.app.id
        + '&country_code=' + this.country.code
        + '&locale_code=' + this.locale
        + '&date_from=' + this.dateFilter.from
        + '&date_to=' + this.dateFilter.to
        + '&rating_from=' + this.ratingFilter.from
        + '&rating_to=' + this.ratingFilter.to;
    },
    preparedReviews() {
      if (this.showSkipped) {
        return this.reviews
      } else {
        return this.reviews.filter(item => !item.skipped);
      }
    },
  },
  watch: {
    showModal() {
      if (this.showModal === true) {
        this.fetchReviews();
      }
    }
  }
}
</script>

<style src="./styles.scss" lang="scss"></style>
