<template>
  <div class="ratings-grade">
    <div v-for="rating in ratings"
         :class="'grade rating-' + rating">
      <table-actions-tooltip>
        <template v-slot:tooltip-icon>
          <div :class="'grade rating-' + rating" :style="'width: ' + getWidths(rating) + 'px'"></div>
        </template>
        <template v-slot:tooltip-text>
          <div class="rating-tooltip">
            <span><span class="css-icons star-icon"></span> {{ rating }}</span>
            <span>{{ data[rating] }}</span>
          </div>
        </template>
      </table-actions-tooltip>
    </div>
  </div>
</template>
<script>
import TableActionsTooltip from "@/components/DataTables/Tools/TableActionsTooltip/index.vue";

export default {
  name: 'RatingsGrade',
  components: {TableActionsTooltip},
  props: {
    data: Object
  },
  data() {
    return {
      width: 300,
    };
  },
  methods: {
    getWidths(rating) {
      return 300 * this.getPercents(rating) / 100;
    },
    getPercents(rating) {
      return this.data[rating] / (this.total / 100);
    },
  },
  computed: {
    ratings() {
      return Object.keys(this.data);
    },
    total() {
      return Object.values(this.data).reduce((a, b) => a + b, 0);
    },
  },
}
</script>
<style scoped lang="scss">
.ratings-grade {
  height: 13px;
  display: flex;

  .rating-tooltip {
    display: flex;
    gap: 10px;
  }

  .grade:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .grade:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .grade {
    height: 13px;

    &.rating-1 {
      background-color: var(--secondary-red)
    }
    &.rating-2 {
      background-color: var(--secondary-orange)
    }
    &.rating-3 {
      background-color: var(--secondary-yellow)
    }
    &.rating-4 {
      background-color: var(--secondary-light-green)
    }
    &.rating-5 {
      background-color: var(--secondary-green)
    }
  }
}
</style>
